<template>
  <Subheader v-if="loggedIn" />
  <div class="container" v-if="loggedIn === false">
    <div class="row mt-5 pt-5">
      <div class="col-12 not-loggedin">
        <h1 class="font-primary success-heading text-center w-100">{{$t('NEED_TO_SIGN_IN')}}</h1>
        <p class="auth-explanation-text">{{$t('SIGN_IN_CTA_TEXT')}}</p>
        <router-link to="/login" class="button">{{$t('SIGN_IN')}}</router-link>
        <router-link to="/register" class="mt-4">{{$t('REGISTER')}}</router-link>
      </div>
    </div>
  </div>
  
  <div v-else class="container pt-5">
    <div class="mt-5 pt-5" v-if="dashboardInfoError">
      <div class="row mt-2 pt-5">
        <div class="d-flex flex-column align-items-center col-12 py-5">
          <p class="text-center">{{$t('ERROR_LOADING_INVESTMENT_INFO')}}</p>
          <button @click="getDashboardData" class="button">{{$t('RETRY')}}</button>
        </div>
      </div>
    </div>
    <!-- <div class="mt-5 pt-5" v-else-if="lastSto?.display_status === 'Processing Payout'">
      <div class="row mb-4">
        <div class="d-flex flex-column align-items-center col-12 py-5">
          <p class="text-center">Currently processing payouts, check back soon to see your investment information</p>
        </div>
      </div>
    </div> -->
    <div :class="!hasCompletedKyc ? 'offset-top' : ''" v-else-if="Object.keys(accountStats).length" class="row content mb-5">
      <div
        class="col-12 wallet-top-controls align-items-end d-flex justify-content-between"
      >
        <h2 class="font-primary mb-0">{{$t('ACCOUNT_STATS')}}</h2>
        <div class="right-controls d-flex align-items-center">
          <button
            v-if="Number(lastSto.status) < 6"
            @click="showDepositModal = true;"
            class="button invest-btn ms-1"
          >
            {{$t('INVEST')}}
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{ Number(lastSto?.status) >= 6 ? $t('SHARES') : $t('ESTIMATED_SHARES')}}</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="fiveIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats.estimated_shares).toFixed(2)
            }}</span>
            <span class="wallet-card-label">{{$t('SHARES')}}</span>
          </div>
        </div>
      </div>
      <div v-if="accountStats.token_price !== null" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('PRICE_PER_SHARE')}}</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats.token_price).toFixed(2)
            }}</span>
            <span class="wallet-card-label">EUR</span>
          </div>
        </div>
      </div>
      <!-- <div v-if="Number(accountStats?.pending_eth) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('PENDING')}} ETH</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.pending_eth).toFixed(8)
            }}</span>
            <span class="wallet-card-label">ETH</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats?.pending_btc) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('PENDING')}} BTC</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.pending_btc).toFixed(8)
            }}</span>
            <span class="wallet-card-label">BTC</span>
          </div>
        </div>
      </div> -->
      <div v-if="Number(accountStats?.committed_eth) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('COMMITTED')}} ETH</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.committed_eth).toFixed(8)
            }}</span>
            <span class="wallet-card-label">ETH</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats?.committed_btc) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('COMMITTED')}} BTC</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.committed_btc).toFixed(8)
            }}</span>
            <span class="wallet-card-label">BTC</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats?.leftover_eth) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('LEFTOVER')}} ETH</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.leftover_eth).toFixed(8)
            }}</span>
            <span class="wallet-card-label">ETH</span>
          </div>
        </div>
      </div>
      <div v-if="Number(accountStats?.leftover_btc) > 0" class="col-12 col-lg-4 col-xl-3">
        <div class="wallet-card">
          <h3 class="mb-3">{{$t('LEFTOVER')}} BTC</h3>
          <div class="d-flex align-items-center justify-content-start">
            <img class="icon me-2" :src="eurBagIcon" alt="" />
            <span class="wallet-card-amount d-inline-block me-1">{{
              Number(accountStats?.leftover_btc).toFixed(8)
            }}</span>
            <span class="wallet-card-label">BTC</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 pt-5" v-else>
      <div class="loader"></div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 class="font-primary">{{$t('ORDER_HISTORY')}}</h2>
      </div>
    </div>
    
    <div class="row row-eq-height mb-5 pb-3 mt-3 pt-4">

      <div
        v-if="Array.isArray(transactions) && transactions.length > 0"
        class="col-12 d-flex flex-wrap"
      >
        <div
          class="token-transaction"
          :key="transaction"
          v-for="transaction of transactions"
        >

          <div class="transaction-section mt-0 d-flex align-items-center">
            <img class="me-2 icon" :src="clockIcon" alt="" /><span>{{
              transaction.display_created_at
            }}</span>
          </div>

          <div class="transaction-section d-flex align-items-center">
            <span class="text-center">{{ transaction.display_type }}</span>
          </div>

          <div :class="transaction.details?.conversion ? 'conversion' : ''" class="transaction-section d-flex align-items-center">
            
            <div class="from-to" v-if="transaction.details?.conversion">
              <div class="d-flex flex-nowrap">
                <img class="icon me-1" :src="eurIcon" alt="" />
                <span
                  class="transaction-address"
                >{{ transaction.details.conversion.from_amount }}
                  BTC</span
                >
              </div>
              <img class="mx-3 arrow-icon" :src="arrowRight2" alt="">
              <div class="d-flex flex-nowrap">
                <img class="icon me-1" :src="eurIcon" alt="" />
                <span
                  class="transaction-address"
                >{{ transaction.details.conversion.to_amount }}
                  EUR</span
                >
              </div>
            </div>
            <div v-else>
              <img class="icon me-1" :src="eurIcon" alt="" />
              <span
                class="transaction-address"
                :title="'test test test test test'"
              >{{ transaction.display_amount }}
                {{ transaction.currency_code }}</span
              >
            </div>
          </div>

          <div class="transaction-section d-flex align-items-center">
            <span>{{$t('COMPLETED')}}</span>
          </div>

          <div class="transaction-section details d-flex align-items-center">
            <a
              v-if="transaction.details && transaction.details.tx_hash"
              class="button white py-2"
              target="_blank"
              :href="`${config.btcExplorerUrl}/${transaction.details?.tx_hash}`"
            >
              {{$t('DETAILS')}}
            </a>
            <div v-else-if="transaction.details && transaction.details.price_per_share" class="conversion-rate">
              <img class="icon me-1" :src="eurIcon" alt="">
              <span>{{transaction.details.price_per_share}} &nbsp; </span>
              <small style="text-transform: lowercase">/ {{$t('SHARE')}}</small>
            </div>
            <div v-else-if="transaction.details && transaction.details.conversion_rate" class="conversion-rate d-flex flex-nowrap">
              <img class="icon me-1" :src="eurIcon" alt="">
              <span class="text-nowrap">{{transaction.details && transaction.details.conversion_rate}} EUR &nbsp; </span>
              <small class="text-nowrap"> / BTC</small>
            </div>
            <div v-else>
              N/A
            </div>
          </div>

        </div>

        <div
          class="
            pagination-wrapper
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <button
            :disabled="currentPage === 1"
            @click="getTransactions(firstPage)"
            class="button white"
          >
            &lt;&lt; <span>{{$t('FIRST')}}</span>
          </button>
          <button
            :disabled="!prevPage"
            @click="prevPage && getTransactions(Number(prevPage))"
            class="button white"
          >
            &lt;<span>{{$t('PREVIOUS')}}</span>
          </button>
          <span class="pagination-current">{{currentPage}} / {{lastPage}}</span>
          <button
            :disabled="!nextPage"
            @click="nextPage && getTransactions(Number(nextPage))"
            class="button white"
          >
            <span>{{$t('NEXT')}}</span> &gt;
          </button>
          <button
            :disabled="lastPage == currentPage"
            @click="lastPage && getTransactions(Number(lastPage))"
            class="button white"
          >
            <span>{{$t('LAST')}}</span> &gt;&gt;
          </button>
        </div>
      </div>
      <div v-else>
        <p class="text-center mt-5">{{$t('NO_ORDERS_YET')}}</p>
      </div>
    </div>
  </div>

  <div
    @click="closeModal"
    @scroll.stop
    @scroll.prevent
    :class="showDepositModal || showWithdrawModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div @click.stop v-if="showDepositModal" class="deposit-modal">
      <div @click="closeModal" class="close-modal">✕</div>
      <template v-if="kycStatus === 3">
        <h3 class="text-center mb-4">{{$t('INVEST')}}</h3>
        <div class="modal-tabs mb-4">
          <!-- <div
            @click="depositCurrency = 'EUR'"
            :class="depositCurrency === 'EUR' ? 'active' : ''"
            class="modal-tab"
          >
            <span>Euro</span>
          </div> -->
          <div
            @click="depositCurrency = 'ETH'; addressCopied = false"
            :class="depositCurrency === 'ETH' ? 'active' : ''"
            class="modal-tab me-1"
          >
            <span>Ethereum</span>
          </div>
          <div
            @click="depositCurrency = 'BTC'; addressCopied = false"
            :class="depositCurrency === 'BTC' ? 'active' : ''"
            class="modal-tab"
          >
            <span>Bitcoin</span>
          </div>
        </div>
        <template v-if="depositCurrency === 'EUR'">
          <p class="text-center mb-4">
            {{$t('DEPOSIT_MODAL_INFO')}}
          </p>

          <div class="invest-bank-info">
            <span class="bank-info-row"
              >{{$t('NAME')}}: <strong>{{ fiatDepositInfo?.bank_name }}</strong></span
            >
            <span class="bank-info-row"
              >{{$t('ADDRESS')}}: <strong>{{ fiatDepositInfo?.bank_address }}</strong></span
            >
            <br />
            <span class="bank-info-row"
              >{{$t('BANK_ACCOUNT')}}: <strong>SI56 6666 6666 6666 666</strong></span
            >
            <span class="bank-info-row"
              >{{$t('REFERENCE_NUMBER')}}:
              <strong>{{ fiatDepositInfo?.reference_number }}</strong></span
            >
          </div>
        </template>
        <template v-else>
          <p class="mt-2 modal-p mb-2 text-center">
            {{$t('DEPOSIT_MODAL_DISCLAIMER')}}
          </p>
          <img class="qr-code mb-2" :src="qrUrl" alt="" />
          <label for="deposit-address">{{$t('DEPOSIT_ADDRESS')}}</label>
          <input
            disabled
            :value="cryptoDepositInfo?.address?.address"
            type="text"
            style="background-color: white; color: #848FA8;"
          />
          <div class="disclaimer-box px-4 py-3 mt-3">
            <label>{{$t('DISCLAIMER')}}</label>
            <p>
                {{$t('DEPOSIT_TERMS_1')}}
              <router-link to="#"
                >{{$t('DEPOSIT_RECOVERY_POLICY')}}</router-link
              >. {{$t('DEPOSIT_TERMS_2')}}
            </p>
          </div>
        </template>

        <button
          @click="depositCurrency == 'BTC' || depositCurrency == 'ETH' ? copyAddress() : closeModal()"
          class="button danger mt-3"
        >
          {{
            depositCurrency == "EUR"
              ? $t('CLOSE')
              : addressCopied
              ? $t('COPIED')
              : $t('COPY_ADDRESS')
          }}
        </button>
      </template>
      <div v-else>
        <h3 class="text-center mb-4">{{$t('INVEST')}}</h3>
        <p class="mb-5 mt-3 text-center">{{$t('KYC_REQUIRED_FOR_INVEST')}}</p>
        <router-link to="/settings" class="button">{{$t('GO_TO_KYC')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import QRCode from "qrcode";
import config from "../../config";
import fiveIcon from "../../assets/images/icon-share-1.svg";
import eurBagIcon from "../../assets/images/icon-euro-funds.svg";
import eurIcon from "../../assets/images/icon-euro.svg";
import btcBagIcon from "../../assets/images/icon-btc-funds.svg";
import returnIcon from "../../assets/images/icon-return.svg";
import arrowRight2 from "../../assets/images/arrow-right2.svg";
import clockIcon from "../../assets/images/clock2.svg";
import btcIcon from "../../assets/images/icon-btc.svg";
import Subheader from '../../components/Subheader.vue';

export default defineComponent({
  name: "Wallet",
  components: {
    Subheader,
  },
  data: function () {
    return {
      hasCompletedKyc: null,
      config: config,
      addressCopied: false,
      btcIcon: btcIcon,
      clockIcon: clockIcon,
      arrowRight2: arrowRight2,
      accountStats: {},
      fiveIcon: fiveIcon,
      eurBagIcon: eurBagIcon,
      eurIcon: eurIcon,
      btcBagIcon: btcBagIcon,
      returnIcon: returnIcon,
      orders: [],
      tokenBalance: "",
      tokenPrice: "",
      tokenTotalValue: "",
      completedOrders: "",
      pendingOrders: "",
      showDepositModal: false,
      showWithdrawModal: false,
      depositCurrency: "ETH",
      depositAddress: "abcdefghjklmnopqrstuvwxyz1234567890",
      qrUrl: "",
      accessToken: "",
      deposits: null,
      withdrawals: null,
      depositCurrencies: [],
      fiatDepositInfo: {},
      cryptoDepositInfo: {},
      loadingDepositInfo: false,
      transactionType: "deposits",
      depositsCurrPage: 1,
      withdrawalsCurrPage: 1,
      orderLoading: false,
      transactions: null,
      nextPage: null,
      prevPage: null,
      currentPage: null,
      lastPage: null,
      loggedIn: true,
      lastSto: null,
      cryptoError: false,
      dashboardInfoError: false
    };
  },
  mounted: function () {
    this.loggedIn = this.$store.state.loggedIn;
    this.$store.watch(state => {
      this.loggedIn = state.loggedIn;
      this.accessToken = state.accessToken;
      this.getDashboardData();
      this.getDepositCurrencies();
      this.getDepositInfo(this.depositCurrency);
      this.getTransactions();
      this.getSto();
      this.kycStatus = state.kycStatus;
      this.hasCompletedKyc = state.userData?.has_confirmed_kyc;
    });
  },
  methods: {
    async getSto() {
      const lastStoRes = await fetch(`${config.API}/api/v1/last-sto`).then(res => res.json());
      this.lastSto = lastStoRes.data.sto;
    },
    getTransactions(pageNum = 1) {
      if (!this.$store.state.loggedIn || !this.accessToken) {
        return;
      }
      fetch(`${config.API}/api/v1/transactions?page=${pageNum}`, {
        headers: {
          ...config.defaultHeaders,
          Accept: "application/json",
          'Content-Type': "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.transactions = data.data.transactions.data;
          this.prevPage = data.data.transactions?.prev_page_url
            ? Number(data.data.transactions.current_page) - 1
            : null;
          this.nextPage = data.data.transactions?.next_page_url
            ? Number(data.data.transactions.current_page) + 1
            : null;
          this.lastPage = data.data.transactions?.last_page;
          this.firstPage = data.data.transactions?.first_page;
          this.currentPage = data.data.transactions?.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copyAddress() {
      this.addressCopied = true;
      navigator.clipboard.writeText(this.cryptoDepositInfo.address.address);
      this.$toast.success(this.$t('COPIED'), {position: 'top'});
    },
    async getDepositCurrencies() {
      if (!this.$store.state.loggedIn || !this.accessToken) {
        return;
      }
      try {
        const res = await fetch(
          `${config.API}/api/v1/get-currencies-for-deposit`,
          {
            headers: {
              ...config.defaultHeaders,
              Accept: "application/json",
              'Content-Type': "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        this.depositCurrencies = res.data.currencies;
      } catch (error) {
        console.log(error);
      }
    },
    async getWithdrawals(pageNum = 1) {
      this.orderLoading = true;
      try {
        const withdrawalsRes = await fetch(
          `${config.API}/api/v1/withdrawals?page=${pageNum}`,
          {
            headers: {
              ...config.defaultHeaders,
              Accept: "application/json",
              'Content-Type': "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        this.withdrawals = withdrawalsRes.data.withdrawals;
        this.orderLoading = false;
      } catch (error) {
        console.log(error);
        this.orderLoading = false;
      }
    },
    async getDepositInfo(currencyCode) {
      this.loadingDepositInfo = true;
      if (!this.$store.state.loggedIn || !this.accessToken) {
        return;
      }
      try {
        const res = await fetch(
          `${config.API}/api/v1/get-deposit-info/${currencyCode}`,
          {
            headers: {
              ...config.defaultHeaders,
              Accept: "application/json",
              'Content-Type': "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        // console.log(res.errors[0] === 'Failed to get info for deposit.');
        // if (res.errors[0] === 'Failed to get info for deposit.') {
        //   this.depositCurrency = 'EUR';
        //   this.cryptoError = true;
        // }
        this.loadingDepositInfo = false;
        if (currencyCode === "EUR") {
          this.fiatDepositInfo = res.data?.deposit_data;
        } else {
          if (!res.data?.deposit_data?.address.address) {
            this.depositCurrency = 'EUR';
            return;
          }
          this.cryptoDepositInfo = res.data?.deposit_data;
          this.renderQR(res.data?.deposit_data?.address.address);
        }
      } catch (error) {
        console.log(error);
      }
    },
    renderQR(string = 'placeholder-string') {
      QRCode.toDataURL(string, {
        scale: 10,
        color: { light: "#fff", dark: "#3F4C62" },
      })
        .then((url) => {
          if (url) {
            this.qrUrl = url;
          } else {
            this.depositCurrency = 'EUR';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeModal() {
      this.showDepositModal = false;
      this.showWithdrawModal = false;
      this.addressCopied = false;
    },
    async getDashboardData() {
      this.dashboardInfoError = false;
      if (!this.$store.state.loggedIn || !this.accessToken) {
        return;
      }
      try {
        const dashboardData = await fetch(
          `${config.API}/api/v1/get-dashboard-info`,
          {
            headers: {
              ...config.defaultHeaders,
              Accept: "application/json",
              'Content-Type': "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        ).then((res) => res.json());
        console.log(dashboardData);
        if (dashboardData.errors) {
          this.dashboardInfoError = true;
        }
        this.accountStats = dashboardData.data.dashboard_info;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    depositCurrency: function (newVal) {
      console.log("changed", newVal);
      this.getDepositInfo(newVal);
    },
    // showWithdrawModal: function(newVal) {
    //   if (newVal) {
    //     document.querySelector('body').style.overflow = 'hidden';
    //   } else {
    //     document.querySelector('body').style.overflow = 'auto';
    //   }
    // },
    // showDepositModal: function(newVal) {
    //   if (newVal) {
    //     document.querySelector('body').style.overflow = 'hidden';
    //   } else {
    //     document.querySelector('body').style.overflow = 'auto';
    //   }
    // }
  },
});
</script>
